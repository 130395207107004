import { LOADING_STATE } from "../../../utilities/loadingState";

export const INITIAL_STATE = {
  rangeFilters: {
    data: null,
    loadingState: LOADING_STATE.NOT_ATTEMPTED,
  },
  exclusionFilters: {
    data: null,
    loadingState: LOADING_STATE.NOT_ATTEMPTED,
  },
};
