import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE } from "./constants";
import { LOADING_STATE } from "../../../utilities/loadingState";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET:
      // reset state to initial values
      return INITIAL_STATE;

    case types.GET_REPORT_FILTERS:
      return produce(state, (draft) => {
        draft.rangeFilters.loadingState = LOADING_STATE.IS_LOADING;
        draft.exclusionFilters.loadingState = LOADING_STATE.IS_LOADING;
      });

    case types.GET_REPORT_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.rangeFilters.data = action.payload.rangeFilters;
        draft.rangeFilters.loadingState = LOADING_STATE.IS_LOADED;
        draft.exclusionFilters.data = action.payload.exclusionFilters;
        draft.exclusionFilters.loadingState = LOADING_STATE.IS_LOADED;
      });

    case types.GET_REPORT_FILTERS_FAILURE:
      return produce(state, (draft) => {
        draft.rangeFilters.data = INITIAL_STATE.rangeFilters.data;
        draft.rangeFilters.loadingState = LOADING_STATE.IS_LOADED;
        draft.exclusionFilters.data = INITIAL_STATE.exclusionFilters.data;
        draft.exclusionFilters.loadingState = LOADING_STATE.IS_LOADED;
      });

    case types.RESET_REPORT_FILTERS:
      return produce(state, (draft) => {
        draft.rangeFilters = INITIAL_STATE.rangeFilters;
        draft.exclusionFilters = INITIAL_STATE.exclusionFilters;
      });

    default:
      return state;
  }
};

export default reducer;
