/**
 * Implements a Sweetalert2 confirmation alert.
 *
 * Returns a Promise.
 */

import Alert from "sweetalert2";
import "./style.scss";

const confirmationAlert = ({
  title = "Are you sure you want to do this?",
  text = "Do you want to continue?",
  type = "warning",
  confirmButtonText = "Continue",
  cancelButtonText = "Cancel",
  showCancelButton = true,
  reverseButtons = true,
  width = 600,
  animation = true,
} = {}) =>
  Alert.fire({
    title,
    text,
    type,
    confirmButtonText,
    cancelButtonText,
    showCancelButton,
    reverseButtons,
    width,
    animation,
    allowOutsideClick: false,
    backdrop: "rgba(0, 0, 0, .75)",
  });

export { confirmationAlert as default };
