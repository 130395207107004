export const INITIAL_STATE = {
  columnSort: {
    mode: "multiple",
    sort: [{ field: "requestedTimestamp", dir: "desc" }],
  },
  columnConfig: [
    {
      dataName: "exportId",
      text: "ID",
      type: "number",
      tooltip: null,
      visible: false,
      sortable: false,
      filterable: false,
      width: 80,
    },
    {
      dataName: "chaseListName",
      text: "List Name",
      type: "string",
      tooltip: null,
      visible: true,
      sortable: true,
      filterable: false,
      width: 550,
    },
    {
      dataName: "exportType",
      text: "Type",
      type: "string",
      tooltip: null,
      visible: true,
      sortable: true,
      filterable: false,
      width: 300,
    },
    {
      dataName: "allowDownload",
      text: " ",
      type: "string",
      tooltip: null,
      visible: true,
      sortable: false,
      filterable: false,
      width: 80,
      resizable: false,
    },
    {
      dataName: "status",
      text: "Status",
      type: "string",
      tooltip: null,
      visible: true,
      sortable: true,
      filterable: false,
      width: 150,
    },
    {
      dataName: "requestedTimestamp",
      text: "Requested On",
      type: "date",
      tooltip: null,
      visible: true,
      sortable: true,
      filterable: false,
      width: 180,
    },
    {
      dataName: "completedTimestamp",
      text: "Completed On",
      type: "date",
      tooltip: null,
      visible: true,
      sortable: true,
      filterable: false,
      width: 180,
    },
  ],
  records: [],
};
