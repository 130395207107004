/*
A small set of utilities to let auth-management store things in sessionStorage.
Not exposed to the user, only gets used internally.

We do not clear sessionStorage, but instead remove items individually, in order not
to break any other part of the app that may use sessionStorage.

Shape of storage: {
  nonce,    used in generagePingUri, isHashAuthenticated
  authConfig,
  token: {
    isAuthenticated: boolean,  set by updateTokenVaues, used in isAuthenticated
    expiration,  set by updateTokenValues, used by isAuthenticated, timeouts
    idToken,     (jwtDecode to get user info),   getUser exposes this stuff
    accessToken,    used for auth headers
    tokenType       used for auth headers
  }
}
*/

const set = (key, value) => {
  // remove instead if setting to undefined or null
  if (value === undefined || value === null) {
    sessionStorage.removeItem(key);
  } else if (typeof value === "string") {
    // prefix strings so we don't try to JSON.parse them when getting
    sessionStorage.setItem(key, "string_" + value);
  } else {
    // numbers, objects, and booleans can all just be JSON.parse'd when getting
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

const get = (key) => {
  let value;
  const valueStr = sessionStorage.getItem(key);
  // recognize and unprefix strings
  if (valueStr !== null && valueStr.startsWith("string_")) {
    value = valueStr.slice(7);
  } else {
    // numbers, objects, booleans, and nulls just get parsed
    value = JSON.parse(valueStr);
  }
  return value;
};

const remove = (key) => {
  sessionStorage.removeItem(key);
};

export default {
  set,
  get,
  remove,
};
