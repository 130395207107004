/* eslint-disable no-restricted-globals */

import { generatePingUri } from "./ping";
import { updateTokenValues } from "./updateTokenValues";

// 1. When user is warned of logout, they can trigger this to get a new token
// 2. It takes a callback to set new timeouts, etc., on successful refresh
export const updateTokenSilently = (onError, onSuccess, ifNotAuthenticated) => {
  // create a hidden iframe
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  // redirect iframe to get ping tokens
  const silent = true;
  const uri = generatePingUri(silent);
  iframe.contentWindow.document.location.href = uri;

  // listen for onload, parse hash and update token if it exists
  iframe.addEventListener("load", () => {
    const iframeLocation = iframe.contentWindow.document.location;
    if (location.origin === iframeLocation.origin && iframeLocation.hash) {
      updateTokenValues(
        iframeLocation.hash,
        onError,
        onSuccess,
        ifNotAuthenticated
      );
    }
    // TODO: If we aren't for some reason able to run updateTokenValues at all,
    // we should tell the app that it failed. But an `else` here gets run regardless.
    // Ideas for how to do this welcome :)

    // remove iframe when complete
    iframe.parentNode.removeChild(iframe);
  });
};
