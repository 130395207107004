import "core-js/stable";
import "regenerator-runtime/runtime";

import "./suppressWarnings";

import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { configureAuthentication } from "./utilities/security/authentication";
import configureStore, { historyAPI } from "./configureStore";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

import "./styles/global.scss";

// setup authentication
configureAuthentication();

// create redux store
const store = configureStore();

const MOUNT_NODE = document.getElementById("root");

ReactDOM.render(
  <ReduxProvider store={store}>
    <ConnectedRouter history={historyAPI}>
      <App />
    </ConnectedRouter>
  </ReduxProvider>,
  MOUNT_NODE
);

// registerServiceWorker();
