import storage from "./storageUtil";
import { AUTH_DOMAINS } from "./constants";

/**
 * This needs to be run before you can use goToPingLogin, logout, or updateTokenSilently.
 *
 * The shape of config needs to be:
 *
 * {
 *  CLIENT_ID
 * }
 *
 * but other overrides can be provided as well.
 *
 * @param {String} environment
 * @param {Object} config
 */
export const initializeConfig = (environment, config) => {
  storage.set(
    "authConfig",
    Object.assign({}, getDefaults(environment), config)
  );
};

const getDefaults = (environment) => {
  const origin = window.location.origin;
  const authDomain = AUTH_DOMAINS[environment];

  return {
    RESPONSE_TYPE: "token id_token",
    REDIRECT_URI: `${origin}/auth`,
    SILENT_REDIRECT_URI: `${origin}/auth`,
    SCOPE: "profile email openid",
    URL: `${authDomain}/as/authorization.oauth2`,
    SERVER: `${authDomain}/ext/sso`,
    LOGOUT_URI: `${authDomain}/ext/logout`,
  };
};
