const PREFIX = "NEW/";

export const SCHEMA_VALIDATION_ERROR = PREFIX + "SCHEMA_VALIDATION_ERROR";
export const SET_IS_LOADING = PREFIX + "SET_IS_LOADING";
export const RESET = PREFIX + "RESET";

export const GET_CONFIGURATION_SUCCESS = PREFIX + "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILURE = PREFIX + "GET_CONFIGURATION_FAILURE";

export const CLEAR_FORM = PREFIX + "CLEAR_FORM";
export const SET_NAME = PREFIX + "SET_NAME";
export const SET_TIMEFRAME = PREFIX + "SET_TIMEFRAME";
export const SET_PARENT_ID = PREFIX + "SET_PARENT_ID";
export const SET_STARTING_FILTER_VALUE = PREFIX + "SET_STARTING_FILTER_VALUE";
export const SET_STARTING_FILTER = PREFIX + "SET_STARTING_FILTER";
export const SET_STARTING_FILTER_LIST = PREFIX + "SET_STARTING_FILTER_LIST";

export const CREATE_CHASE_LIST_SUCCESS = PREFIX + "CREATE_CHASE_LIST_SUCCESS";
export const CREATE_CHASE_LIST_FAILURE = PREFIX + "CREATE_CHASE_LIST_FAILURE";
export const LOCK_CHASE_LIST_SUCCESS = PREFIX + "LOCK_CHASE_LIST_SUCCESS";
export const LOCK_CHASE_LIST_FAILURE = PREFIX + "LOCK_CHASE_LIST_FAILURE";

export const GET_LISTS_SUCCESS = PREFIX + "GET_LISTS_SUCCESS";
export const GET_LISTS_FAILURE = PREFIX + "GET_LISTS_FAILURE";
export const GET_LISTS_COLUMNS_SUCCESS = PREFIX + "GET_LISTS_COLUMNS_SUCCESS";
export const GET_LISTS_COLUMNS_FAILURE = PREFIX + "GET_LISTS_COLUMNS_FAILURE";
export const RESET_LISTS = PREFIX + "RESET_LISTS";
