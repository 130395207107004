import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE } from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET:
      // reset state to initial values
      return INITIAL_STATE;

    case types.SET_IS_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });

    case types.GET_CONFIGURATION_SUCCESS:
      return produce(state, (draft) => {
        const { baseData, timeframes } = action.payload;
        draft.config.baseData = baseData;
        draft.config.timeframes = timeframes;
      });

    case types.GET_CONFIGURATION_FAILURE:
      return produce(state, (draft) => {
        // TODO push error into error list
        const { baseData, timeframes } = INITIAL_STATE.config;
        draft.config.baseData = baseData;
        draft.config.timeframes = timeframes;
      });

    case types.CLEAR_FORM:
      return produce(state, (draft) => {
        draft.form = INITIAL_STATE.form;
      });

    case types.SET_NAME:
      return produce(state, (draft) => {
        draft.form.name = action.payload === "" ? null : action.payload;
      });

    case types.SET_TIMEFRAME:
      return produce(state, (draft) => {
        const payload = action.payload;
        // set selected timeframe value
        draft.form.timeframeValue = payload;
        draft.form.parentId = INITIAL_STATE.form.parentId;

        // find selected timeframe
        const timeframe = state.config.timeframes.find(
          (timeframe) => timeframe.key === payload.value
        );
        // update list of chart definitions for selected timeframe
        draft.config.chartDefinitions =
          timeframe && timeframe.data
            ? timeframe.data
            : INITIAL_STATE.config.chartDefinitions;
      });

    case types.SET_PARENT_ID:
      return produce(state, (draft) => {
        draft.form.parentId = action.payload;
      });

    case types.SET_STARTING_FILTER_VALUE:
      return produce(state, (draft) => {
        draft.form.filtersFromOption = action.payload;
        draft.form.filtersFromListId = INITIAL_STATE.form.filtersFromListId;
      });

    case types.SET_STARTING_FILTER:
      return produce(state, (draft) => {
        draft.form.filtersFromListId = action.payload;
        if (action.payload < 1) {
          draft.form.filtersFromList = null;
        }
      });

    case types.SET_STARTING_FILTER_LIST:
      return produce(state, (draft) => {
        draft.form.filtersFromList = action.payload;
      });

    // TODO handle chase list success
    // should result in a call for lock/mode and redirect to edit screen
    case types.CREATE_CHASE_LIST_SUCCESS:
      return produce(state, (draft) => {
        const { payload } = action;
        draft.newChaseList = payload.data;
      });

    // TODO handle chase list creation error
    case types.CREATE_CHASE_LIST_FAILURE:
      return produce(state, (draft) => {
        draft.newChaseList = INITIAL_STATE.newChaseList;
      });

    case types.LOCK_CHASE_LIST_SUCCESS:
      return produce(state, (draft) => {
        draft.newChaseListLock = action.payload;
      });
    case types.LOCK_CHASE_LIST_FAILURE:
      return produce(state, (draft) => {
        draft.newChaseListLock = INITIAL_STATE.newChaseListLock;
      });

    case types.GET_LISTS_SUCCESS:
      return produce(state, (draft) => {
        draft.chaseLists = action.payload;
      });

    case types.GET_LISTS_FAILURE:
    case types.RESET_LISTS:
      return produce(state, (draft) => {
        draft.chaseLists = INITIAL_STATE.chaseLists;
      });

    case types.GET_LISTS_COLUMNS_SUCCESS:
      return produce(state, (draft) => {
        draft.applyFiltersFromColumnConfig = action.payload;
      });

    case types.GET_LISTS_COLUMNS_FAILURE:
      return produce(state, (draft) => {
        draft.applyFiltersFromColumnConfig = INITIAL_STATE.columnConfig;
      });

    default:
      return state;
  }
};

export default reducer;
