export const LOADING_STATE = {
  IS_LOADED: 2,
  IS_LOADING: 1,
  NOT_ATTEMPTED: 0,
  FAILED: -1,
};

export function loadingNotAttempted(loadingState) {
  return loadingState === LOADING_STATE.NOT_ATTEMPTED;
}

export function isLoaded(loadingState) {
  return loadingState === LOADING_STATE.IS_LOADED;
}

export function isLoading(loadingState) {
  return loadingState === LOADING_STATE.IS_LOADING;
}

export function isLoadingOrLoaded(loadingState) {
  return (
    loadingState === LOADING_STATE.IS_LOADED ||
    loadingState === LOADING_STATE.IS_LOADING
  );
}

export function failedToLoad(loadingState) {
  return loadingState === LOADING_STATE.FAILED;
}
