import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE, DEFAULT_NOTIFICATION } from "./constants";

function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.NOTIFICATION_ADD:
      return produce(state, (draft) => {
        const lastNotification = draft[draft.length - 1];
        const duplicate = getDuplicate(lastNotification, action.payload);
        const nextNotification = {
          ...DEFAULT_NOTIFICATION,
          ...action.payload,
          duplicate,
        };
        draft.push(nextNotification);
      });
    case types.NOTIFICATION_SHIFT:
      return produce(state, (draft) => {
        draft.shift();
      });
    default:
      return state;
  }
}

function getDuplicate(prevNotification, nextNotification) {
  if (!prevNotification) return 0;
  if (prevNotification.text !== nextNotification.text) return 0;
  return prevNotification.duplicate + 1;
}

export default notificationsReducer;
