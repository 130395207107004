import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE } from "./constants";
import { LOADING_STATE } from "../../../../utilities/loadingState";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET:
      // reset state slice to initial values
      return INITIAL_STATE;

    case types.SET_LIST_IS_SAVING:
      return produce(state, (draft) => {
        draft.listIsSaving = action.payload;
      });

    case types.GET_REPORT_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        const {
          exclusionFilters,
          rangeFilters,
          overrides,
          columnSort,
        } = action.payload;

        draft.original.exclusionFilters = exclusionFilters;
        draft.original.rangeFilters = rangeFilters;
        draft.original.overrides = overrides;
        draft.original.columnSort = columnSort;

        draft.edited.exclusionFilters = exclusionFilters;
        draft.edited.rangeFilters = rangeFilters;
        draft.edited.overrides = overrides;
        draft.edited.columnSort = columnSort;

        draft.report.columnSort.sort = columnSort;
      });
    case types.GET_REPORT_FILTERS_FAILURE:
      return produce(state, (draft) => {
        draft.original = INITIAL_STATE.original;
        draft.edited = INITIAL_STATE.edited;
        draft.report.columnSort.sort = INITIAL_STATE.report.columnSort.sort;
      });

    // Overrides
    case types.EDIT_OVERRIDES:
      return produce(state, (draft) => {
        draft.edited.overrides = action.payload;
        draft.report.pagination.start = 0;
      });

    // Optimization parameters
    case types.EDIT_OPTIMIZATION_PARAMETERS:
      return produce(state, (draft) => {
        draft.edited.details.optimizationParameters = action.payload;
      });
    case types.RESET_OPTIMIZATION_PARAMETERS:
      return produce(state, (draft) => {
        draft.edited.details.optimizationParameters =
          draft.report.details.optimizationParameters;
      });

    // Range filter groups
    case types.GET_RANGE_FILTERS_GROUPS_AND_SUBGROUPS_SUCCESS:
      return produce(state, (draft) => {
        draft.rangeFilterGroups = action.payload;
      });
    case types.GET_RANGE_FILTERS_GROUPS_AND_SUBGROUPS_FAILURE:
      return produce(state, (draft) => {
        draft.rangeFilterGroups = INITIAL_STATE.rangeFilterGroups;
      });

    // Exclusion filter groups and subgroup exclusions
    case types.GET_EXCLUSION_FILTERS_GROUPS_AND_SUBGROUPS_SUCCESS:
      return produce(state, (draft) => {
        draft.exclusionFilterGroups = action.payload;
      });
    case types.GET_EXCLUSION_FILTERS_GROUPS_AND_SUBGROUPS_FAILURE:
      return produce(state, (draft) => {
        draft.exclusionFilterGroups = action.payload;
      });
    case types.GET_EXCLUSION_FILTERS_SUBGROUP_VALUES_SUCCESS:
      return produce(state, (draft) => {
        draft.availableExclusions = action.payload;
      });
    case types.GET_EXCLUSION_FILTERS_SUBGROUP_VALUES_FAILURE:
    case types.RESET_EXCLUSION_FILTERS_SUBGROUP_VALUES:
      return produce(state, (draft) => {
        draft.availableExclusions = [];
      });

    // Toggle filters on/off
    case types.TOGGLE_EXCLUSION_FILTER:
      return produce(state, (draft) => {
        const { id, enabled } = action.payload;

        const filterIndex = draft.edited.exclusionFilters.findIndex(
          (item) => item.id === id
        );
        draft.edited.exclusionFilters[filterIndex].enabled = enabled;
        draft.report.pagination.start = 0;
      });
    case types.TOGGLE_RANGE_FILTER:
      return produce(state, (draft) => {
        const { id, enabled } = action.payload;

        const filterIndex = draft.edited.rangeFilters.findIndex(
          (item) => item.id === id
        );
        draft.edited.rangeFilters[filterIndex].enabled = enabled;
        draft.report.pagination.start = 0;
      });

    // Exclusion filter CRUD
    case types.ADD_EXCLUSION_FILTER:
      return produce(state, (draft) => {
        draft.edited.exclusionFilters.push(action.payload);
        draft.report.pagination.start = 0;
      });
    case types.EDIT_EXCLUSION_FILTER:
      return produce(state, (draft) => {
        const exclusionFilterIndex = draft.edited.exclusionFilters.findIndex(
          (exclusionFilter) => exclusionFilter.id === action.payload.id
        );
        draft.edited.exclusionFilters[exclusionFilterIndex] = action.payload;
        draft.report.pagination.start = 0;
      });
    case types.DELETE_EXCLUSION_FILTER:
      return produce(state, (draft) => {
        const filters = draft.edited.exclusionFilters.filter(
          (item) => item.id !== action.id
        );
        draft.edited.exclusionFilters = filters;
        draft.report.pagination.start = 0;
      });

    // Range filter CRUD
    case types.ADD_RANGE_FILTER:
      return produce(state, (draft) => {
        draft.edited.rangeFilters.push(action.payload);
        draft.report.pagination.start = 0;
      });
    case types.EDIT_RANGE_FILTER:
      return produce(state, (draft) => {
        const rangerFilterIndex = draft.edited.rangeFilters.findIndex(
          (rangeFilter) => rangeFilter.id === action.payload.id
        );
        draft.edited.rangeFilters[rangerFilterIndex] = action.payload;
        draft.report.pagination.start = 0;
      });
    case types.DELETE_RANGE_FILTER:
      return produce(state, (draft) => {
        const filters = draft.edited.rangeFilters.filter(
          (item) => item.id !== action.id
        );
        draft.edited.rangeFilters = filters;
        draft.report.pagination.start = 0;
      });

    case types.SET_REPORT_IS_LOADING:
      return produce(state, (draft) => {
        draft.report.loading = action.payload;
      });

    case types.GET_STATE_TABS_SUCCESS:
      return produce(state, (draft) => {
        draft.report.tabConfig.tabs = action.payload;
      });

    case types.GET_STATE_TABS_FAILURE:
      return produce(state, (draft) => {
        draft.report.tabConfig.tabs = INITIAL_STATE.report.tabConfig.tabs;
        draft.report.state = INITIAL_STATE.report.state;
      });

    case types.TAB_SELECTED:
      return produce(state, (draft) => {
        const index = action.payload;
        const tabs = state.report.tabConfig.tabs;
        const type = tabs[index]["type"];
        draft.report.tabConfig.initialTab = index;
        draft.report.state = type;
      });

    case types.GET_REPORT_DETAILS_SUCCESS:
      return produce(state, (draft) => {
        const { payload } = action;
        draft.report.details = payload;
        draft.original.details = payload;
        draft.edited.details = payload;
      });

    case types.GET_REPORT_DETAILS_FAILURE:
      return produce(state, (draft) => {
        const { details } = INITIAL_STATE.report;
        draft.report.details = details;
        draft.original.details = details;
        draft.edited.details = details;
      });

    case types.GET_REPORT_DATA_SUCCESS:
      return produce(state, (draft) => {
        const { columns, report } = action.payload;
        draft.report.columnConfig = columns;
        draft.report.records = report.data;
        draft.report.metadata.totalRecords = report.metadata.pagination.total;
        draft.report.metrics = report.metadata.metrics;
        draft.report.rangeFilters = report.metadata.rangeFilters;

        draft.edited.details.chartCounts = report.metadata.chartCounts;
        draft.edited.details.memberCounts = report.metadata.memberCounts;
      });

    case types.GET_REPORT_DATA_FAILURE:
      return produce(state, (draft) => {
        draft.report = INITIAL_STATE.report;
      });

    case types.SET_NAME:
      return produce(state, (draft) => {
        draft.edited.details.name = action.payload;
      });

    case types.SET_PAGE_SIZE:
      return produce(state, (draft) => {
        draft.report.pagination.pageSize = Number(action.payload);
      });

    case types.SET_PAGINATION_START:
      return produce(state, (draft) => {
        draft.report.pagination.start = action.payload;
      });

    case types.SET_COLUMN_SORT:
      return produce(state, (draft) => {
        draft.report.columnSort.sort = action.payload;
      });

    case types.SHOW_UNLOCK_DIALOG:
      return produce(state, (draft) => {
        draft.unlockDialogIsOpen = true;
      });
    case types.HIDE_UNLOCK_DIALOG:
      return produce(state, (draft) => {
        draft.unlockDialogIsOpen = false;
      });

    case types.SHOW_OPTIMIZATION_DIALOG:
      return produce(state, (draft) => {
        draft.optimizationDialogIsOpen = true;
      });
    case types.HIDE_OPTIMIZATION_DIALOG:
      return produce(state, (draft) => {
        draft.optimizationDialogIsOpen = INITIAL_STATE.optimizationDialogIsOpen;
      });

    case types.SET_GRID_FILTERS_ACTIVE_COLUMN_NAME:
      return produce(state, (draft) => {
        const {
          loadingState,
          records,
          selectedValues,
        } = INITIAL_STATE.report.gridFiltersDialog;
        draft.report.gridFiltersActiveColumn = action.payload;
        draft.report.gridFiltersDialog.loadingState = loadingState;
        draft.report.gridFiltersDialog.records = records;
        draft.report.gridFiltersDialog.selectedValues = selectedValues;
      });

    case types.SHOW_GRID_FILTERS_DIALOG:
      return produce(state, (draft) => {
        draft.report.gridFiltersDialog.isOpen = true;
        draft.report.columnFiltersNext = draft.report.columnFilters;
      });
    case types.HIDE_GRID_FILTERS_DIALOG:
      return produce(state, (draft) => {
        const {
          gridFiltersActiveColumn,
          gridFiltersDialog,
          columnFiltersNext,
        } = INITIAL_STATE.report;
        draft.report.gridFiltersActiveColumn = gridFiltersActiveColumn;
        draft.report.gridFiltersDialog = gridFiltersDialog;
        draft.report.columnFiltersNext = columnFiltersNext;
      });

    case types.UPDATE_NEXT_COLUMN_FILTERS:
      return produce(state, (draft) => {
        const { gridFiltersActiveColumn, columnFiltersNext } = draft.report;
        const match = columnFiltersNext.find(
          (column) => column.dataName === gridFiltersActiveColumn
        );
        if (match) {
          // replace values
          match.values = action.payload;
        } else {
          // insert new
          columnFiltersNext.push({
            dataName: gridFiltersActiveColumn,
            values: action.payload,
          });
        }
      });

    case types.RESET_NEXT_COLUMN_FILTERS:
      return produce(state, (draft) => {
        draft.report.columnFiltersNext = INITIAL_STATE.report.columnFiltersNext;
      });

    case types.APPLY_NEXT_COLUMN_FILTERS:
      return produce(state, (draft) => {
        // remove entries without values
        const { columnFiltersNext } = draft.report;
        const filters = columnFiltersNext.filter(
          (option) => option.values.length
        );

        draft.report.columnFilters = filters;
        draft.report.pagination.start = 0;
      });

    case types.REQUEST_GRID_FILTER_COLUMN_VALUES:
      return produce(state, (draft) => {
        draft.report.gridFiltersDialog.loadingState = LOADING_STATE.IS_LOADING;
        draft.report.gridFiltersDialog.records = [];

        // copy currently selected values from next state
        const { gridFiltersActiveColumn, columnFiltersNext } = draft.report;
        const activeColumn = columnFiltersNext.find(
          ({ dataName }) => dataName === gridFiltersActiveColumn
        );
        draft.report.gridFiltersDialog.selectedValues =
          activeColumn?.values || [];
      });

    case types.REQUEST_GRID_FILTER_COLUMN_VALUES_SUCCESS:
      return produce(state, (draft) => {
        draft.report.gridFiltersDialog.loadingState = LOADING_STATE.IS_LOADED;
        draft.report.gridFiltersDialog.records = action.payload;
      });
    case types.REQUEST_GRID_FILTER_COLUMN_VALUES_FAILURE:
      return produce(state, (draft) => {
        draft.report.gridFiltersDialog.loadingState = LOADING_STATE.FAILED;
        draft.report.gridFiltersDialog.records =
          INITIAL_STATE.report.gridFiltersDialog.records;
        // TODO surface error in payload
      });

    default:
      return state;
  }
};

export default reducer;
