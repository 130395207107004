import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import layout from "./containers/LayoutWrapper/modules/reducer";
import notifications from "./containers/Notification/modules/reducer";
import environments from "./containers/chase-list/Environments/modules/reducer";
import sscl from "./containers/chase-list/modules/reducer";
import summary from "./containers/chase-list/Summary/modules/reducer";
import newList from "./containers/chase-list/New/modules/reducer";
import editList from "./containers/chase-list/Edit/modules/reducer";
import exports from "./containers/chase-list/Exports/modules/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    notifications,
    sscl,
    environments,
    summary,
    newList,
    editList,
    exports,
  });

export default createRootReducer;
