import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE } from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EXPORTS_SUCCESS:
      return produce(state, (draft) => {
        draft.records = action.payload;
      });
    case types.GET_EXPORTS_FAILURE:
      return produce(state, (draft) => {
        const { records } = INITIAL_STATE;
        draft.records = records;
      });

    case types.SET_COLUMN_SORT:
      return produce(state, (draft) => {
        draft.columnSort.sort = action.payload || INITIAL_STATE.columnSort.sort;
      });

    default:
      return state;
  }
};

export default reducer;
