import jwtDecode from "jwt-decode";
import storage from "./storageUtil";

const urldecode = (text) => decodeURIComponent(text.replace(/\+/g, " "));

const parseUrlHash = (hashParam) => {
  let hashProps = {};
  // eslint-disable-next-line prefer-destructuring
  const hash = hashParam.split("#")[1];
  if (hash) {
    // String will be formatted key=value&key=value&
    // Transforming to JS object
    hashProps = hash.split("&").reduce((accumulator, item) => {
      const [key, value] = item.split("=");
      if (key !== "expires_in") {
        accumulator[key] = urldecode(value);
      }
      return accumulator;
    }, {});
  }
  return hashProps;
};

const isHashAuthenticated = (hashParams) => {
  const token = hashParams.id_token ? jwtDecode(hashParams.id_token) : {};
  const nonce = storage.get("nonce") || "";
  return token.nonce === nonce.toString() && !!token.roles;
};

const parseToken = (token) => {
  const tokenProps = {};
  const accessToken = jwtDecode(token.access_token);
  tokenProps.isAuthenticated = true;
  tokenProps.expiration = accessToken.exp * 1000;
  tokenProps.accessToken = token.access_token;
  tokenProps.idToken = token.id_token;
  tokenProps.tokenType = token.token_type;
  return tokenProps;
};

const storeTokenValues = (hashParams) => {
  storage.set("token", parseToken(hashParams));
};

export const updateTokenValues = (
  hash,
  onError,
  onSuccess,
  ifNotAuthenticated
) => {
  const hashParams = parseUrlHash(hash);
  if (hashParams.error) {
    if (onError) onError(hashParams.error, hashParams.error_description);
  } else if (isHashAuthenticated(hashParams) === true) {
    // yay we're secure, maybe store other things like user, navigate somewhere
    storeTokenValues(hashParams);
    if (onSuccess) onSuccess();
  } else {
    // not authenticated, go to login or something
    if (ifNotAuthenticated) ifNotAuthenticated();
  }
};
