import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import { INITIAL_STATE } from "./constants";
import history from "./history";

let store = null;

const configureStore = (initialState = INITIAL_STATE) => {
  // use redux devtools extension if available, otherwise use redux compose
  const composeEnhancer =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  /**
   * add the following pieces of middleware:
   * - thunk: for async/await support in action creators
   * - routerMiddleware: Syncs the location/URL path to the state
   */
  const middleware = [thunk, routerMiddleware(history)];

  // apply the middleware into a store enhancer
  const storeEnhancer = composeEnhancer(applyMiddleware(...middleware));

  // create the core reducer
  const reducer = createRootReducer(history);

  // finally, create and return the store
  store = createStore(reducer, initialState, storeEnhancer);

  return store;
};

export { configureStore as default, store, history as historyAPI };
