import { LOCALE, CURRENCY_CODE } from "../../constants";

/**
 * Number formatters
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 */
const FLOAT = {
  style: "decimal",
  minimumFractionDigits: 2,
};

const CURRENCY = {
  ...FLOAT,
  style: "currency",
  currency: CURRENCY_CODE,
};

const CURRENCY_NO_DECIMAL = {
  ...CURRENCY,
  minimumFractionDigits: 0,
};

const CURRENCY_COMPACT = {
  ...CURRENCY,
  notation: "compact",
  compactDisplay: "short",
};

const CURRENCY_COMPACT_NO_DECIMAL = {
  ...CURRENCY_NO_DECIMAL,
  ...CURRENCY_COMPACT,
};

const RISK_SCORE = {
  style: "decimal",
  minimumFractionDigits: 3,
};

export const number = Intl.NumberFormat(LOCALE).format;

export const float = Intl.NumberFormat(LOCALE, FLOAT).format;

export const currency = Intl.NumberFormat(LOCALE, CURRENCY).format;

export const currencyCompact = Intl.NumberFormat(LOCALE, CURRENCY_COMPACT)
  .format;

export const currencyNoDecimal = Intl.NumberFormat(LOCALE, CURRENCY_NO_DECIMAL)
  .format;

export const currencyCompactNoDecimal = Intl.NumberFormat(
  LOCALE,
  CURRENCY_COMPACT_NO_DECIMAL
).format;

export const riskScore = Intl.NumberFormat(LOCALE, RISK_SCORE).format;

export {
  number as numberFormat,
  float as floatFormat,
  currency as currencyFormat,
  currencyCompact as currencyCompactFormat,
  currencyNoDecimal as currencyFormatNoDecimal,
  currencyCompactNoDecimal as currencyCompactFormatNoDecimal,
  riskScore as riskScoreFormat,
};
