import storage from "./storageUtil";

/*
Takes a config object and returns a headers object extended with the config.
Use for making requests.
*/

export const createAuthHeaders = (config) => {
  const token = storage.get("token");
  const headers = {
    Authorization: `${token.tokenType} ${token.accessToken}`,
    Identity: token.idToken,
  };

  return Object.assign({}, headers, config);
};

/*
Gets tokenType, accessToken, and idToken from sessionStorage and returns them.
Use for making requests if you want something more DIY than createAuthHeaders.
*/

export const getTokenValuesForHeaders = () => {
  const token = storage.get("token");

  return {
    tokenType: token.tokenType,
    accessToken: token.accessToken,
    idToken: token.idToken,
  };
};
