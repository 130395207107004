/**
 * Implements Yup schema validation helpers.
 * See https://www.npmjs.com/package/yup
 */

import { SCHEMA_VALIDATOR_OPTIONS } from "./constants";

/**
 * Validates a single object against the passed in Yup schema.
 * Returns a cast object when valid, or false when invalid.
 *
 * @param {Object} item item to be validated
 * @param {ObjectSchema} schema schema to be used to validate item
 * @returns {Object|Boolean} a valid item, or false when invalid.
 */
export const validateItem = (item, schema) => {
  try {
    return schema.validateSync(item, SCHEMA_VALIDATOR_OPTIONS);
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Validates a dataset against the passed in Yup schema and returns all valid records.
 *
 * @param {Array} items items to be validated
 * @param {ObjectSchema} schema schema to be used to validate items
 * @returns {Array} a list of valid items
 */
export const validateItems = (items, schema) =>
  items.map((item) => validateItem(item, schema)).filter((item) => item);

/**
 * Attempts to convert a value to the given type, if supported.
 * Unsupported types are returned unchanged.
 *
 * @param {String} type a supported data type.
 * @param {Any} value value to be converted
 * @returns {Any} converted value, when possible, or the original value
 */
export const castValue = (type, value) => {
  switch (type) {
    case "number":
      return Number(value);
    case "date":
      return Date.parse(value);
    case "boolean":
      return Boolean(value);
    default:
      return value;
  }
};
