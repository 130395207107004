const PREFIX = "ENV/";

export const RESET = PREFIX + "RESET";

export const GET_DATA = PREFIX + "GET_DATA";
export const GET_DATA_SUCCESS = PREFIX + "GET_DATA_SUCCESS";
export const GET_DATA_FAILURE = PREFIX + "GET_DATA_FAILURE";
export const SET_FORM_LOB = PREFIX + "SET_FORM_LOB";
export const SET_FORM_TENANT_ID = PREFIX + "SET_FORM_TENANT_ID";
export const SET_ACTIVE_ENVIRONMENT = PREFIX + "SET_ACTIVE_ENVIRONMENT";
