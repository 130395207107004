import { LOADING_STATE } from "../../../../utilities/loadingState";

export const INITIAL_STATE = {
  form: {
    lob: null,
    tenantId: null,
  },
  clientsByLob: {
    // holds list of environments/tenants
    // See src/schemas/domain/sscl/auth/clients-by-lob/index.js
    data: [],
    loadingState: LOADING_STATE.NOT_ATTEMPTED,
  },
  // holds environment object used to request API session token
  // See src/schemas/domain/sscl/auth/environment/index.js
  activeEnvironment: null,
};
