import { createBrowserHistory } from "history";
import confirmationAlert from "./utilities/alerts/confirmation";

const history = createBrowserHistory({
  async getUserConfirmation(message, callback) {
    const response = await confirmationAlert({
      title: message,
    });
    callback(response.value);
  },
});

export default history;
