import React from "react";
import MessageFormatter from "../components/Detail/Optimized/Messages";
import {
  numberFormat,
  currencyFormat,
  currencyCompactFormat,
  riskScoreFormat,
  floatFormat,
} from "../../../../utilities/formatters/numbers";

export const INITIAL_STATE = {
  listSummary: [],
  records: [],
  metrics: null,
  editHistory: null,
  columnConfig: [],
  columnSort: {
    mode: "multiple",
    sort: [],
  },
  exportDialogIsOpen: false,
  listToExport: null,
  deleteDialogIsOpen: false,
  listToDelete: null,
  listToEdit: null,
  listToLock: null,
};

export const OPTIMIZATION_CONFIG = {
  parameters: {
    costPerChart: {
      label: "Cost per Chart",
      formatter: currencyFormat,
    },
    minimumChartsPerMember: {
      label: "Minimum Number of Charts per Member",
      formatter: numberFormat,
    },
    maximumChartsPerMember: {
      label: "Maximum Number of Charts per Member",
      formatter: numberFormat,
    },
    targetScoreLift: {
      label: "Target Score Lift",
      formatter: riskScoreFormat,
    },
    targetProjectSize: {
      label: "Target Project Size",
      formatter: numberFormat,
    },
    targetRevenue: {
      label: "Target Revenue",
      formatter: currencyCompactFormat,
    },
    targetROI: {
      label: "Target ROI",
      formatter: floatFormat,
    },
  },
  projections: {
    messages: {
      label: "Messages",
      // eslint-disable-next-line react/display-name
      formatter: (data) => <MessageFormatter data={data} />,
    },
    totalNumberCharts: {
      label: "Total Number of Charts",
      formatter: numberFormat,
    },
    totalCost: {
      label: "Total Cost",
      formatter: currencyCompactFormat,
    },
    scoreLift: {
      label: "Score Lift",
      formatter: riskScoreFormat,
    },
    revenue: {
      label: "Revenue",
      formatter: currencyCompactFormat,
    },
    roi: {
      label: "ROI",
      formatter: floatFormat,
    },
  },
};
