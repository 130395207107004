import { validateItems } from "../../../schemas/utilities";
import menuItemSchema from "../../../schemas/domain/uicl/masthead/product-menu-item";

// Uses either aud or app_id to remove the current app from masthead product menu
export const getProductMenuFromApps = (apps = [], currentAppId, currentAud) => {
  const menuItems = apps
    // remove the current app (legacy CIAM format)
    .filter((app) => app.uri !== currentAud)
    // remove the current app (federated CIAM format)
    .filter((app) => app.id !== currentAppId)
    // build menu items
    .map(({ id, uri, name }) => ({
      id,
      path: uri,
      label: name.toUpperCase(),
    }));

  // validate menu items
  const validMenuItems = validateItems(menuItems, menuItemSchema);

  // product menu expects a two-dimensional array
  return validMenuItems.length ? [validMenuItems] : [];
};
