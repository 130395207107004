import { historyAPI } from "../../../configureStore";
import { APPLICATION_NAME, AUTHENTICATED_USER_MENU } from "../../../constants";

export const DEFAULT_LOGO_REDIRECT_PATH = "/";
export const CURRENT_PRODUCT_NAME = APPLICATION_NAME;
export const META_LABEL = null;

export const EMPTY_LIST = [];
export const EMPTY_FN = () => false;

// the state of the masthead for unauthenticated users
export const INITIAL_STATE = {
  mastheadConfig: {
    userFirstName: null,
    userLastName: null,
    userEmail: null,
    navMenuItems: AUTHENTICATED_USER_MENU,
    logoRedirect: DEFAULT_LOGO_REDIRECT_PATH,
    currentProductName: CURRENT_PRODUCT_NAME,
    productMenuItems: EMPTY_LIST,
    onProductMenuOpenClose: EMPTY_FN,
    metaLabel: META_LABEL,
    showNotifications: false,
    notificationAlerts: [],
    notificationsCallbacks: {
      markAllAsRead: EMPTY_FN,
      onPrevious: EMPTY_FN,
      onNext: EMPTY_FN,
    },
    onAlertMenuOpenClose: EMPTY_FN,
    onLogOutSelect: () => {
      historyAPI.push("/logout");
    },
  },
};
