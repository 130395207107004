export const INITIAL_STATE = [];

export const DEFAULT_NOTIFICATION = {
  type: "positive",
  text: "",
  onDismiss: () => {},
  autoDismiss: true,
  duplicate: 0,
  timer: 3000,
};
