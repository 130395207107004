const PREFIX = "EDIT/";

export const SET_LIST_IS_SAVING = PREFIX + "SET_LIST_IS_SAVING";
export const SET_REPORT_IS_LOADING = PREFIX + "SET_REPORT_IS_LOADING";

export const SCHEMA_VALIDATION_ERROR = PREFIX + "SCHEMA_VALIDATION_ERROR";
export const RESET = PREFIX + "RESET";

export const GET_REPORT_FILTERS_SUCCESS = PREFIX + "GET_REPORT_FILTERS_SUCCESS";
export const GET_REPORT_FILTERS_FAILURE = PREFIX + "GET_REPORT_FILTERS_FAILURE";

export const EDIT_OVERRIDES = PREFIX + "EDIT_OVERRIDES";

export const TOGGLE_EXCLUSION_FILTER = PREFIX + "TOGGLE_EXCLUSION_FILTER";

export const TOGGLE_RANGE_FILTER = PREFIX + "TOGGLE_RANGE_FILTER";

export const EDIT_OPTIMIZATION_PARAMETERS =
  PREFIX + "EDIT_OPTIMIZATION_PARAMETERS";
export const RESET_OPTIMIZATION_PARAMETERS =
  PREFIX + "RESET_OPTIMIZATION_PARAMETERS";

export const EDIT_EXCLUSION_FILTER = PREFIX + "EDIT_EXCLUSION_FILTER";
export const ADD_EXCLUSION_FILTER = PREFIX + "ADD_EXCLUSION_FILTER";
export const DELETE_EXCLUSION_FILTER = PREFIX + "DELETE_EXCLUSION_FILTER";

export const EDIT_RANGE_FILTER = PREFIX + "EDIT_RANGE_FILTER";
export const ADD_RANGE_FILTER = PREFIX + "ADD_RANGE_FILTER";
export const DELETE_RANGE_FILTER = PREFIX + "DELETE_RANGE_FILTER";

export const RESET_EXCLUSION_FILTERS_SUBGROUP_VALUES =
  PREFIX + "RESET_EXCLUSION_FILTERS_SUBGROUP_VALUES";

export const GET_EXCLUSION_FILTERS_GROUPS_AND_SUBGROUPS_SUCCESS =
  PREFIX + "GET_EXCLUSION_FILTERS_GROUPS_AND_SUBGROUPS_SUCCESS";
export const GET_EXCLUSION_FILTERS_GROUPS_AND_SUBGROUPS_FAILURE =
  PREFIX + "GET_EXCLUSION_FILTERS_GROUPS_AND_SUBGROUPS_FAILURE";
export const GET_EXCLUSION_FILTERS_SUBGROUP_VALUES_SUCCESS =
  PREFIX + "GET_EXCLUSION_FILTERS_SUBGROUP_VALUES_SUCCESS";
export const GET_EXCLUSION_FILTERS_SUBGROUP_VALUES_FAILURE =
  PREFIX + "GET_EXCLUSION_FILTERS_SUBGROUP_VALUES_FAILURE";

export const GET_RANGE_FILTERS_GROUPS_AND_SUBGROUPS_SUCCESS =
  PREFIX + "GET_RANGE_FILTERS_GROUPS_AND_SUBGROUPS_SUCCESS";
export const GET_RANGE_FILTERS_GROUPS_AND_SUBGROUPS_FAILURE =
  PREFIX + "GET_RANGE_FILTERS_GROUPS_AND_SUBGROUPS_FAILURE";

export const GET_STATE_TABS_SUCCESS = PREFIX + "GET_STATE_TABS_SUCCESS";
export const GET_STATE_TABS_FAILURE = PREFIX + "GET_STATE_TABS_FAILURE";
export const TAB_SELECTED = PREFIX + "TAB_SELECTED";

export const GET_REPORT_DETAILS_SUCCESS = PREFIX + "GET_REPORT_DETAILS_SUCCESS";
export const GET_REPORT_DETAILS_FAILURE = PREFIX + "GET_REPORT_DETAILS_FAILURE";
export const GET_REPORT_DATA_SUCCESS = PREFIX + "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAILURE = PREFIX + "GET_REPORT_DATA_FAILURE";

export const SET_NAME = PREFIX + "SET_NAME";

export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const SET_PAGINATION_START = PREFIX + "SET_PAGINATION_START";

export const SET_COLUMN_SORT = PREFIX + "SET_COLUMN_SORT";

export const SHOW_UNLOCK_DIALOG = PREFIX + "SHOW_UNLOCK_DIALOG";
export const HIDE_UNLOCK_DIALOG = PREFIX + "HIDE_UNLOCK_DIALOG";

export const SHOW_OPTIMIZATION_DIALOG = PREFIX + "SHOW_OPTIMIZATION_DIALOG";
export const HIDE_OPTIMIZATION_DIALOG = PREFIX + "HIDE_OPTIMIZATION_DIALOG";

export const SHOW_GRID_FILTERS_DIALOG = PREFIX + "SHOW_GRID_FILTERS_DIALOG";
export const HIDE_GRID_FILTERS_DIALOG = PREFIX + "HIDE_GRID_FILTERS_DIALOG";

export const SET_GRID_FILTERS_ACTIVE_COLUMN_NAME =
  PREFIX + "SET_GRID_FILTERS_ACTIVE_COLUMN_NAME";
export const REQUEST_GRID_FILTER_COLUMN_VALUES =
  PREFIX + "REQUEST_GRID_FILTER_COLUMN_VALUES";
export const REQUEST_GRID_FILTER_COLUMN_VALUES_SUCCESS =
  PREFIX + "REQUEST_GRID_FILTER_COLUMN_VALUES_SUCCESS";
export const REQUEST_GRID_FILTER_COLUMN_VALUES_FAILURE =
  PREFIX + "REQUEST_GRID_FILTER_COLUMN_VALUES_FAILURE";

export const UPDATE_NEXT_COLUMN_FILTERS = PREFIX + "UPDATE_NEXT_COLUMN_FILTERS";
export const RESET_NEXT_COLUMN_FILTERS = PREFIX + "RESET_NEXT_COLUMN_FILTERS";
export const APPLY_NEXT_COLUMN_FILTERS = PREFIX + "APPLY_NEXT_COLUMN_FILTERS";
