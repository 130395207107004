import { LOADING_STATE } from "../../../../utilities/loadingState";

export const INITIAL_STATE = {
  report: {
    loading: false,
    details: {
      name: "",
    },
    tabConfig: {
      tabs: [],
      initialTab: 0,
    },
    state: "OPTIMIZED", // used in chart filtering / set when grid tabs are toggled
    columnConfig: [],
    columnSort: {
      mode: "single", // TODO enable "multiple" column sorting once column filters and ordering is completed
      sort: [],
    },
    usesLocalSort: false, // disable local sort for server sorted datasets
    metadata: {
      totalRecords: 0,
    },
    pagination: {
      start: 0,
      pageSize: 50,
    },
    records: [],
    rangeFilters: [],
    metrics: [],
    /**
     * Holds selected filter values to be applied to the charts grid and are
     * reset when the current chart is dismissed.
     *
     * Its value is set when the user clicks Apply on the grid filters dialog.
     *
     * Shape: [ { dataName: "column name", values: [values], ... } ]
     */
    columnFilters: [],
    columnFiltersNext: [],
    gridFiltersActiveColumn: null,
    gridFiltersDialog: {
      isOpen: false,
      loadingState: LOADING_STATE.NOT_ATTEMPTED,
      records: [],
      selectedValues: [],
    },
  },
  availableExclusions: [],
  exclusionFilterGroups: [],
  rangeFilterGroups: [],
  original: {},
  edited: {},
  optimizationDialogIsOpen: false,
  unlockDialogIsOpen: false,
  listIsSaving: false,
};
