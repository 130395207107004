import jwtDecode from "jwt-decode";
import storage from "./storageUtil";

/*
Gets and decodes the idToken from sessionStorage and returns it as a JS object
*/

export const getUser = (cb) => {
  const token = storage.get("token");
  if (!token || !token.idToken) {
    return {};
  }

  const decoded = jwtDecode(token.idToken);

  if (cb) {
    return cb(decoded);
  }
  return decoded;
};
