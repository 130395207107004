const PREFIX = "SUMMARY/";

export const GET_LISTS_SUMMARY_SUCCESS = PREFIX + "GET_LISTS_SUMMARY_SUCCESS";
export const GET_LISTS_SUMMARY_FAILURE = PREFIX + "GET_LISTS_SUMMARY_FAILURE";
export const GET_LISTS_SUCCESS = PREFIX + "GET_LISTS_SUCCESS";
export const GET_LISTS_FAILURE = PREFIX + "GET_LISTS_FAILURE";
export const GET_LISTS_COLUMNS_SUCCESS = PREFIX + "GET_LISTS_COLUMNS_SUCCESS";
export const GET_LISTS_COLUMNS_FAILURE = PREFIX + "GET_LISTS_COLUMNS_FAILURE";
export const DELETE_LIST_SUCCESS = PREFIX + "DELETE_LIST_SUCCESS";
export const UPDATE_LIST_STATUS_SUCCESS = PREFIX + "UPDATE_LIST_STATUS_SUCCESS";
export const UPDATE_LIST_STATUS_FAILURE = PREFIX + "UPDATE_LIST_STATUS_FAILURE";
export const GET_METRICS_REQUEST = PREFIX + "GET_METRICS_REQUEST";
export const GET_METRICS_SUCCESS = PREFIX + "GET_METRICS_SUCCESS";
export const GET_METRICS_FAILURE = PREFIX + "GET_METRICS_FAILURE";

export const SHOW_EXPORT_DIALOG = PREFIX + "SHOW_EXPORT_DIALOG";
export const HIDE_EXPORT_DIALOG = PREFIX + "HIDE_EXPORT_DIALOG";

export const SHOW_DELETE_DIALOG = PREFIX + "SHOW_DELETE_DIALOG";
export const HIDE_DELETE_DIALOG = PREFIX + "HIDE_DELETE_DIALOG";

export const DECREMENT_LIST_SUMMARY_COUNT_SUCCESS =
  PREFIX + "DECREMENT_LIST_SUMMARY_COUNT_SUCCESS";
export const INCREMENT_LIST_SUMMARY_COUNT_SUCCESS =
  PREFIX + "INCREMENT_LIST_SUMMARY_COUNT_SUCCESS";
export const REMOVE_LIST_SUCCESS = PREFIX + "REMOVE_LIST_SUCCESS";

export const SET_COLUMN_SORT = PREFIX + "SET_COLUMN_SORT";

export const SET_LIST_TO_EDIT = PREFIX + "SET_LIST_TO_EDIT";
export const RESET_LIST_TO_EDIT = PREFIX + "RESET_LIST_TO_EDIT";
export const LOCK_LIST_SUCCESS = PREFIX + "LOCK_LIST_SUCCESS";
export const LOCK_LIST_FAILURE = PREFIX + "LOCK_LIST_FAILURE";

export const RESET_RECORDS = PREFIX + "RESET_RECORDS";

export const RESET_AUDITS = PREFIX + "RESET_AUDITS";
export const GET_LIST_AUDITS_SUCCESS = PREFIX + "GET_LIST_AUDITS_SUCCESS";
export const GET_LIST_AUDITS_FAILURE = PREFIX + "GET_LIST_AUDITS_FAILURE";
