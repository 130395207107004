import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE } from "./constants";
import { LOADING_STATE } from "../../../../utilities/loadingState";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET:
      return produce(state, (draft) => {
        draft.form = INITIAL_STATE.form;
        draft.clientsByLob.data = INITIAL_STATE.clientsByLob.data;
        draft.activeEnvironment = INITIAL_STATE.activeEnvironment;
      });

    case types.GET_DATA:
      return produce(state, (draft) => {
        draft.clientsByLob.loadingState = LOADING_STATE.IS_LOADING;
      });

    case types.GET_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.clientsByLob.data = action.payload;
        draft.clientsByLob.loadingState = LOADING_STATE.IS_LOADED;
      });

    case types.GET_DATA_FAILURE:
      return produce(state, (draft) => {
        draft.clientsByLob.data = INITIAL_STATE.clientsByLob.data;
        draft.clientsByLob.loadingState = LOADING_STATE.FAILED;
      });

    case types.SET_FORM_LOB:
      return produce(state, (draft) => {
        draft.form.lob = action.payload;
        draft.form.tenantId = INITIAL_STATE.form.tenantId;
      });

    case types.SET_FORM_TENANT_ID:
      return produce(state, (draft) => {
        draft.form.tenantId = action.payload;
      });

    case types.SET_ACTIVE_ENVIRONMENT:
      return produce(state, (draft) => {
        draft.activeEnvironment = action.payload;
      });

    default:
      return state;
  }
};

export default reducer;
