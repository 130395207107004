import produce from "immer";
import * as types from "../modules/actions/types";
import { INITIAL_STATE } from "./constants";
import { SUPPORT_MENU } from "../../../constants";
import Gravatar from "../../../utilities/gravatar";
import { getProductMenuFromApps } from "./helpers";

const reducer = (state = INITIAL_STATE, action) => {
  // return early if an action is not passed
  if (!action) return state;

  switch (action.type) {
    case types.SET_USER:
      const {
        aud,
        app_id,
        apps,
        photo,
        email,
        given_name,
        family_name,
        uuid,
      } = action.payload;
      const productMenuItems = getProductMenuFromApps(apps, app_id, aud);

      return produce(state, (draft) => {
        draft.mastheadConfig.avatarImage =
          photo || new Gravatar(email).getImage();
        draft.mastheadConfig.userEmail = email;
        draft.mastheadConfig.userFirstName = given_name;
        draft.mastheadConfig.userLastName = family_name;
        draft.mastheadConfig.userId = uuid;
        draft.mastheadConfig.productMenuItems = productMenuItems;
        draft.mastheadConfig.showNotifications = !!draft.mastheadConfig
          .notificationAlerts.length;
        draft.mastheadConfig.supportMenuConfig = SUPPORT_MENU;
      });

    case types.UNSET_USER:
      return INITIAL_STATE;

    case types.TOOGLE_NOTIFICATIONS:
      return produce(state, (draft) => {
        draft.mastheadConfig.showNotifications = Boolean(action.payload);
      });

    case types.SET_AUTHORIZED_USER_MENU:
      return produce(state, (draft) => {
        draft.mastheadConfig.navMenuItems = action.payload;
      });

    case types.SET_META_LABEL:
      return produce(state, (draft) => {
        draft.mastheadConfig.metaLabel = action.payload;
      });

    default:
      return state;
  }
};

export default reducer;
