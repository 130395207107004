/**
 * Models a UICL product menu item
 */

import { object, string } from "yup";
import uuid from "../../../../common/uuid";

const schema = object({
  id: uuid,
  path: string().required().trim(),
  label: string().required().trim(),
  labelDecoration: string().nullable().trim().default(null),
});

export default schema;
