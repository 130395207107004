export const INITIAL_STATE = {
  loading: false,
  config: {
    // TODO replace this with an API call once auth is in place
    baseData: {
      createDate: null,
    },
    timeframes: [],
    chartDefinitions: [],
    startingFilters: [
      {
        key: "startingFilter",
        value: [
          {
            key: "recommended",
            value: "Recommended Filters",
            enabled: true,
          },
          {
            key: "saved",
            value: "Use Filters from Saved Chase List",
            enabled: true,
          },
        ],
      },
    ],
    buttons: {
      clear: {
        label: "Clear",
        buttonType: "diminished",
        size: "medium",
        type: "reset",
      },
      submit: {
        label: "Generate Draft Chase List",
        buttonType: "emphasized",
        size: "medium",
        type: "submit",
      },
    },
  },
  form: {
    name: null,
    timeframeValue: null,
    parentId: null,
    filtersFromOption: null,
    filtersFromList: null,
    filtersFromListId: null,
  },

  chaseLists: [],
  applyFiltersFromColumnConfig: [],
  applyFiltersFromColumnSort: {
    mode: "multiple",
    sort: [],
  },
  // contains the ID of a newly created list
  newChaseList: null,
  // contains the locking details of a newly created list
  newChaseListLock: null,
};
