import React, { Suspense, lazy } from "react";
import Tooltip from "react-tooltip";
const ErrorBoundary = lazy(() => import("./components/ErrorBoundary"));
const LayoutWrapper = lazy(() => import("./containers/LayoutWrapper"));
const Routes = lazy(() => import("./Routes"));

const App = () => (
  <Suspense fallback={<div />}>
    <LayoutWrapper>
      <ErrorBoundary>
        <Tooltip
          className="react-tooltip"
          id="tooltip"
          multiline
          effect="solid"
          place="top"
          delayShow={200}
        />
        <Routes />
      </ErrorBoundary>
    </LayoutWrapper>
  </Suspense>
);

export default App;
