import storage from "./storageUtil";

const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

const getParams = (silent = false) => {
  const authConfig = storage.get("authConfig");
  const redirectUri = silent
    ? authConfig.SILENT_REDIRECT_URI
    : authConfig.REDIRECT_URI;
  const nonce = storage.get("nonce") || getRandomInt(9999999999);
  storage.set("nonce", nonce);
  const params = {
    client_id: authConfig.CLIENT_ID,
    response_type: authConfig.RESPONSE_TYPE,
    redirect_uri: redirectUri,
    scope: authConfig.SCOPE,
    nonce,
  };
  return silent ? Object.assign({}, params, { prompt: "none" }) : params;
};

// used by gotoPingLogin with no argument, by updateTokenSilently with `silent` as `true`
export const generatePingUri = (silent = false) => {
  const authConfig = storage.get("authConfig");
  const finalParams = getParams(silent);
  const query = Object.keys(finalParams)
    .map(
      (k) => `${encodeURIComponent(k)}=${encodeURIComponent(finalParams[k])}`
    )
    .join("&");
  return `${authConfig.URL}?${query}`;
};

export const gotoPingLogin = () => {
  const uri = generatePingUri();
  window.location = uri;
};
