import storage from "./storageUtil";
import { getUser } from "./getUser";

/*
Returns true if:
- there is a token with isAuthenticated = true
- the token has not expired
- the user has at least one role

Intended for things like auth on enter / route change once logged in.
*/

export const isAuthenticated = (role) => {
  let authenticated = false;
  const token = storage.get("token");

  if (!token) {
    return authenticated;
  }

  const user = getUser();
  const expired = Date.now() > token.expiration;
  const validRoles = role
    ? !!user.roles && user.roles.includes(role)
    : !!user.roles;

  if (token.isAuthenticated && !expired && validRoles) {
    authenticated = true;
  } else {
    // TODO: Removing things here could break things, depending on what the app does if not authenticated
    storage.remove("authConfig");
    storage.remove("nonce");
    storage.remove("token");
  }

  return authenticated;
};
