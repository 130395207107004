import { produce } from "immer";
import * as types from "./actions/types";
import { INITIAL_STATE } from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_LISTS_SUMMARY_SUCCESS:
      return produce(state, (draft) => {
        draft.listSummary = action.payload;
      });
    case types.GET_LISTS_SUMMARY_FAILURE:
      return produce(state, (draft) => {
        draft.listSummary = INITIAL_STATE.listSummary;
      });

    case types.GET_LISTS_SUCCESS:
      return produce(state, (draft) => {
        draft.records = action.payload;
      });
    case types.GET_LISTS_FAILURE:
      return produce(state, (draft) => {
        const { records } = INITIAL_STATE;
        draft.records = records;
      });

    case types.GET_LISTS_COLUMNS_SUCCESS:
      return produce(state, (draft) => {
        draft.columnConfig = action.payload;
      });
    case types.GET_LISTS_COLUMNS_FAILURE:
      return produce(state, (draft) => {
        draft.columnConfig = INITIAL_STATE.columnConfig;
      });

    case types.DELETE_LIST_SUCCESS:
      return produce(state, (draft) => {
        // remove deleted record
        draft.records = state.records.filter(
          (record) => record.id !== action.id
        );

        // find list by status
        const indexOfStatus = state.listSummary.findIndex(
          (item) => item.status === action.status
        );
        // when a list is found, update its total
        if (indexOfStatus > -1) {
          draft.listSummary[indexOfStatus].total = draft.records.length;
        }
      });

    case types.GET_METRICS_REQUEST:
      return produce(state, (draft) => {
        draft.metrics = INITIAL_STATE.metrics;
      });

    case types.GET_METRICS_SUCCESS:
      return produce(state, (draft) => {
        draft.metrics = action.payload;
      });
    case types.GET_METRICS_FAILURE:
      return produce(state, (draft) => {
        draft.metrics = INITIAL_STATE.metrics;
      });

    case types.DECREMENT_LIST_SUMMARY_COUNT_SUCCESS:
      return produce(state, (draft) => {
        const indexOfStatus = state.listSummary.findIndex(
          (item) => item.status === action.status
        );
        draft.listSummary[indexOfStatus].total =
          state.listSummary[indexOfStatus].total - 1;
      });

    case types.INCREMENT_LIST_SUMMARY_COUNT_SUCCESS:
      return produce(state, (draft) => {
        const indexOfStatus = state.listSummary.findIndex(
          (item) => item.status === action.status
        );
        draft.listSummary[indexOfStatus].total =
          state.listSummary[indexOfStatus].total + 1;
      });

    case types.REMOVE_LIST_SUCCESS:
      return produce(state, (draft) => {
        draft.records = state.records.filter((r) => r.id !== action.id);
      });

    case types.SHOW_EXPORT_DIALOG:
      return produce(state, (draft) => {
        draft.exportDialogIsOpen = true;
        draft.listToExport = action.payload;
      });

    case types.HIDE_EXPORT_DIALOG:
      return produce(state, (draft) => {
        const { listToExport } = INITIAL_STATE;
        draft.exportDialogIsOpen = false;
        draft.listToExport = listToExport;
      });

    case types.SHOW_DELETE_DIALOG:
      return produce(state, (draft) => {
        draft.deleteDialogIsOpen = true;
        draft.listToDelete = action.payload;
      });

    case types.HIDE_DELETE_DIALOG:
      return produce(state, (draft) => {
        const { listToDelete } = INITIAL_STATE;
        draft.deleteDialogIsOpen = false;
        draft.listToDelete = listToDelete;
      });

    case types.SET_COLUMN_SORT:
      return produce(state, (draft) => {
        draft.columnSort.sort = action.payload;
      });

    case types.SET_LIST_TO_EDIT:
      return produce(state, (draft) => {
        const { listToLock } = INITIAL_STATE;
        draft.listToEdit = action.payload;
        draft.listToLock = listToLock;
      });
    case types.RESET_LIST_TO_EDIT:
      return produce(state, (draft) => {
        const { listToEdit, listToLock } = INITIAL_STATE;
        draft.listToEdit = listToEdit;
        draft.listToLock = listToLock;
      });

    case types.RESET_RECORDS:
      return produce(state, (draft) => {
        const { records } = INITIAL_STATE;
        draft.records = records;
      });

    case types.LOCK_LIST_SUCCESS:
      return produce(state, (draft) => {
        draft.listToLock = action.payload;
      });
    case types.LOCK_LIST_FAILURE:
      return produce(state, (draft) => {
        draft.listToLock = INITIAL_STATE.listToLock;
      });

    case types.GET_LIST_AUDITS_SUCCESS:
      return produce(state, (draft) => {
        draft.editHistory = action.payload;
      });

    case types.GET_LIST_AUDITS_FAILURE:
    case types.RESET_AUDITS:
      return produce(state, (draft) => {
        draft.editHistory = INITIAL_STATE.editHistory;
      });

    default:
      return state;
  }
};

export default reducer;
