import pkg from "../package.json";

export const APPLICATION_NAME = pkg.description;
export const INITIAL_STATE = {};

export const AUTH_CONFIG = {
  CLIENT_ID: window?.SSCL?.CIAM_CLIENT_ID,
};

// how long before session expiration to initiate silent token refresh (minutes)
export const MINUTES_BEFORE_SILENT_TOKEN_UPDATE = 5;

export const WEB_STORAGE_CONFIG = {
  storage: sessionStorage,
  namespace: "clc",
};

// menu items to display to authenticated users
export const AUTHENTICATED_USER_MENU = [];

// menu items to display to authorized users
export const AUTHORIZED_USER_MENU = [
  [
    { id: 0, label: "Projects and Lists", path: "/projects-and-lists/draft" },
    { id: 1, label: "Create New List", path: "/lists/new" },
  ],
  [
    {
      id: 0,
      label: "My Exports",
      path: "/exports",
    },
  ],
  [
    {
      id: 0,
      label: "Switch Environments",
      path: "/environments",
    },
  ],
];

export const SUPPORT_MENU = {
  shouldShow: true,
  showBottomAction: false,
  linkData: {
    // URLs are configured in AWS SSM and will be injected at build time.
    staticContent: [
      {
        label: "Online Help",
        url: window?.SSCL?.SUPPORT_MENU.HOME,
      },
      {
        label: "Getting Started",
        url: window?.SSCL?.SUPPORT_MENU.GETTING_STARTED,
      },
      {
        label: "Frequently Asked Questions",
        url: window?.SSCL?.SUPPORT_MENU.FAQ,
      },
      {
        label: "Privacy Notice",
        url: "https://www.changehealthcare.com/privacy-notice",
      },
    ],
  },
};

/**
 * Used by the useColumnFormats hook to make an educated guess about
 * a column's formatting.
 */
export const COLUMN_FORMATS = {
  number: [
    {
      keyword: "score",
      format: "{0:n3}",
    },
    {
      keyword: "amount",
      format: "{0:c2}",
    },
    {
      keyword: "revenue",
      format: "{0:c2}",
    },
    {
      keyword: "roi",
      format: "{0:c2}",
    },
    {
      keyword: "month",
      format: "{0:n2}",
    },
  ],
};

export const CHART_KEY = "chartId";

export const GRID_PAGINATION_OPTIONS = [20, 50, 100];
export const GRID_SELECTED_ATTRIBUTE = "_selected"; // used to determine whether to mark a row checked
export const OVERRIDE_TYPE_ATTRIBUTE = "_override"; // used to inject special override icon column

export const FILTER_OVERRIDE_STATES = [
  "OPTIMIZED",
  "ALL",
  "INCLUDED",
  "EXCLUDED",
  "OVERRIDDEN",
];

export const DELAY_SPEED_FAST = 200;
export const DELAY_SPEED_NORMAL = 400;
export const DELAY_SPEED_SLOW = 800;
export const DELAY_SPEED_SLOWEST = 1600;

export const LOCALE = "en-US";
export const CURRENCY_CODE = "USD";
