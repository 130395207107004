const PREFIX = "EXPORTS/";

export const GET_EXPORTS_SUCCESS = PREFIX + "GET_EXPORTS_SUCCESS";
export const GET_EXPORTS_FAILURE = PREFIX + "GET_EXPORTS_FAILURE";

export const DOWNLOAD_EXPORT = PREFIX + "DOWNLOAD_EXPORT";
export const DOWNLOAD_EXPORT_SUCCESS = PREFIX + "DOWNLOAD_EXPORT_SUCCESS";
export const DOWNLOAD_EXPORT_FAILURE = PREFIX + "DOWNLOAD_EXPORT_FAILURE";

export const SET_COLUMN_SORT = PREFIX + "SET_COLUMN_SORT";
