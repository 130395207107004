import storage from "./storageUtil";

/*
Removes all auth information from sessionStorage and sends you to the LOGOUT_URI
*/

export const logout = () => {
  const authConfig = storage.get("authConfig");
  storage.remove("authConfig");
  storage.remove("nonce");
  storage.remove("token");
  window.location.href = authConfig.LOGOUT_URI;
};
