import React from "react";
import styled from "styled-components";

const MessageText = styled.p`
  padding-left: 1rem;
  white-space: initial;
  line-height: initial;
`;

const Messages = ({ data }) => {
  if (!data) return null;

  return data.map((item, index) => (
    <MessageText key={index}>{item}</MessageText>
  ));
};

export default Messages;
